<template>
    <!--Columns Selector-->
    <div class="br">
    <v-card>
    <div id="listOptionsContainer">
      <div class="flex-container w45 jcsb">
        <div v-if="columns.length > 0" class="center-container">
          <Select
            :name="'Select Columns'"
            :is-multi-select="true"
            v-model="selectedColumns"
            :allOptions="columns"
            :placeholderText="'Select Columns'"
            :selectedText="'columns'"
          />
      </div>
      <div v-if="patients && patients.length > 0" class="center-container dark-text">
        {{`${$t('displaying_patient_filters')} ` }} <div class="pd-lr">{{ `${$t(patients.length)} `}}</div> {{ `${$t('of_patient_filters')}` }} <div class="pd-lr">{{ `${$t(totalPatients)}` }}</div> {{`${$t('patients')}`}}

      </div>
      <div class="center-container">
      <PageTurner
        @changePageEvent="changePage"
        :pageSize="pageSize"
        :totalItems="totalPatients"
        :currentPage="selectedPage"
        :pageTurnerSize="pageTurnerSize"
      />
      </div>
      </div>
    </div>
    <div class="tp">
      <Table
        v-if="!isPatientsLoading"
        :columns="tableColumns"
        :data="tabledData"
        :pageSize="pageSize"
        :loading="isPatientsLoading"
      ></Table>
        <div v-else class="loading"><i class='fa fa-fw fa-spinner fa-spin'></i>{{$t('loading_new_patients')}}</div>
    </div>
    </v-card>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Select from '../../../../shared/components/Select.vue'
import PageTurner from '../../../../shared/components/PageTurner.vue'
import Table from '../../../../shared/components/Table.vue'
import EventBus from '@/app/shared/event-bus.js'
export default {
  name: 'DetailsTable',
  metaInfo: {
    title: 'Patient Management'
  },
  components: {
    Select,
    PageTurner,
    Table
  },
  data: function () {
    return {
      selectedColumns: [],
      tableColumns: [],
      tabledData: [],
      columnDetailsMappedByValue: {},
      columnsError: false,
      pageTurnerSize: 5
    }
  },
  mounted: function () {
    if (this.columns.length > 0) {
      this.selectedColumns = this.filterColumns(this.columns)
    } if (this.columns.length >= 9) {
      this.selectedColumns = this.filterColumns(this.columns.slice(0, 9))
    }
    EventBus.$on('clickTableEvent', this.itemClicked)
    EventBus.$on('LANGUAGE_CHANGE', this.getPatients)
  },
  computed: {
    ...mapState('UnifiedPatient', [
      'columns',
      'patients',
      'numberPages',
      'selectedPage',
      'isPatientsLoading',
      'totalPatients',
      'pageSize'
    ])
  },
  methods: {
    filterColumns (arr) {
      return arr.filter(ele => !!ele).map((ele, id) => ({ Key: ele.Key, Value: ele.Value }))
    },
    changePage (number) {
      if (number < 1 || number > this.numberPages) {
        return
      }

      this.setCurrentPage(number)
      this.getPatients()
    },
    itemClicked (column, item) {
      if (column === 'id') {
        window.open('/dashboard/patient/' + item.id, '_blank')
      }
    },
    ...mapActions('UnifiedPatient', [
      'getPatients',
      'setCurrentPage'
    ])
  },
  watch: {
    columns () {
      if (this.columns.length > 0) {
        this.selectedColumns = this.filterColumns(this.columns)
      } if (this.columns.length >= 9) {
        this.selectedColumns = this.filterColumns(this.columns.slice(0, 9))
      }
    },
    selectedColumns (nv, ov) {
      if (nv.length !== 0) {
        var tc = this.selectedColumns.map(item => ({
          key: item.Key,
          name: item.Value,
          sortable: item.Sorting,
          clickable: true
        }))
        this.tableColumns = tc
      }
    },
    tableColumns (nv, ov) {
      if (nv.length === 0) {
        this.tabledData = []
      } else {
        this.tabledData = this.patients
      }
    }
  }
}
</script>

<style scoped>
.jcsb {
  justify-content: space-between;
}
.flex-container {
  display: flex;
}
.v-sheet.v-card {
  border-radius: 10px;
  background-color: var(--background-color-primary-light-dark);
}
.center-container {
  display: flex;
  align-items: center;
}
.br {
  border-radius: 20px;
  box-sizing: unset!important;
}
#listOptionsContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: var(--background-color-primary-light-dark);
}
.pd-lr {
  padding-right: 10px;
  padding-left: 10px;
  font-weight: bolder;
}
.tp {
  background-color: var(--background-color-primary-light-dark);
}
.loading {
  color: var(--text-primary-color);
}
</style>
